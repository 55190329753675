@import "./layout";

#app-landingpage {
    @include column();
    min-height: 100vh;
    width: 100%;
    margin: 0px auto;
    background-color: #f1f3f2;
    background-image: url("../public/images/bg.png");
    background-position: top right;
    background-repeat: no-repeat;
    background-size: min(100vw, 680px) auto;

    .quentinha-strip {
        position: absolute;
        max-width: calc(100vw + 56px);
        margin-left: -56px;
        text-transform: uppercase;
        overflow: hidden;
        white-space: nowrap;

        pre {
            font-family: 'Epilogue';
            font-size: 14px;
            margin: 0px;
            letter-spacing: 0px;
            width: 3076.22px;
            text-size-adjust: 100%;
        }

        &#top-strip {
            top: 13.9px;

            pre {
                animation: fromRight 20s linear infinite;
            }
        }

        &#bottom-strip {
            position: fixed;
            bottom: 16px;

            pre {
                animation: fromLeft 20s linear infinite;
            }
        }
    }

    header#app-header {
        @include column(center, center, 14.5px);
        max-width: calc(100% - 50px);
        margin: 93px auto 0px auto;
        padding: 0px 25px;

        #baladinho {
            align-self: flex-end;
            width: 64.6px;
            height: 82.6px;
            opacity: 0;
            animation: appear 0.5s ease-out forwards;
        }
    
        #logo-quentinha {
            width: 327px;
            height: 95px;
            opacity: 0;
            animation: appear 0.5s ease-out 0.2s forwards;
        }
    }

    #text {
        margin: calc(68.7px + max(0px, calc(100vh - 640px))) auto 16px auto;
        margin: calc(68.7px + max(0px, calc(100dvh - 640px))) auto 16px auto;
        padding-left: 12.85px;
        padding-right: 150.15px;
        font-size: 14px;
        opacity: 0;
        animation: appear 0.5s ease-out 0.4s forwards;
    }

    #app-body-cta {
        position: relative;
        z-index: 3;
        margin: 0 auto;
        border-color: #d780ff;
        opacity: 0;
        animation: appear 0.5s ease-out 0.6s forwards;

        button {
            background-color: #d780ff;
        }
    }
}

@keyframes appear {
	from {
		opacity: 0;
		transform: translateY(15%);
	}
	to {
		opacity: 1;
		transform: translateY(0%);
	}
}

@keyframes fromRight {
    from {
        transform: translateX(0);
    }
    to {
        transform: translate(-20%);
    }
}

@keyframes fromLeft {
    from {
        transform: translateX(-20%);
    }
    to {
        transform: translate(0);
    }
}