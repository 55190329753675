@import "./layout";

body {
	background-color: $bg-color;
}

#app-leadcapture {
	@include column(center, center);

	#app-header {
		@include column();
		position: relative;
		padding: 16px;

		#app-header-back-btn {
			position: absolute;
			top: 32px;
			left: 32px;
			z-index: 2;
			opacity: 0;
			animation: appear 0.5s ease-out forwards;
		}

		#app-header-bg {
			img {
				border-radius: 30px;
				opacity: 0;
				animation: appear 0.5s ease-out forwards;

				@media only screen and (min-width: 380px) {
					width: 343px;
					height: 142px;
				}
		
				@media only screen and (max-width: 380px) {
					width: 100%;
					max-width: calc(100vw - 37px);
					min-width: calc(100vw - 40px);
					height: auto;
				}
			}
		}

		#app-header-logo {
			img {
				margin-top: -30px;
				opacity: 0;
				animation: appear 0.5s ease-out 0.2s forwards;

				@media only screen and (min-width: 380px) {
					width: 231px;
					height: 88px;
				}
		
				@media only screen and (max-width: 380px) {
					width: 100%;
					max-width: calc(calc(100vw - 37px) * 231 / 343);
					min-width: calc(calc(100vw - 40px) * 231 / 343);
					height: auto;
				}
			}
		}
	}

	#app-body {
		@include column(flex-start, null, 24px);
		//margin-bottom: 124px;

		@media only screen and (min-width: 380px) {
			width: 327px;
		}

		@media only screen and (max-width: 380px) {
			width: 100%;
			max-width: calc(100vw - 53px);
			min-width: calc(100vw - 56px);
		}

		h2 {
			color: $fg-light-color;
			font-size: 13px;
			line-height: normal;
			text-transform: uppercase;
		}

		h3, p {
			color: $fg-color;
			font-size: 15px;
			line-height: 24px;
			letter-spacing: 0.15px;
			margin: 0px;
		}

		h2, h3 {
			font-weight: 700;
			opacity: 0;
			animation: appear 0.5s ease-out 0.4s forwards;
		}

		p {
			opacity: 0;
			animation: appear 0.5s ease-out 0.6s forwards;
		}

		label {
			width: 100%;

			.input {
				@include row(center, null, 8px);
				width: 100%;
				max-width: 291px;
				padding: 16px;
				border-radius: 18px;
				border: 1px solid rgba($fg-light-color, 0.22);
				background: $bg-color;
				cursor: text;
				opacity: 0;
				animation: appear 0.5s ease-out 0.8s forwards;

				.instagram-prefix {
					color: $fg-color;
					font-family: Epilogue;
					font-size: 15px;
					font-weight: 400;
					line-height: 125%;
				}

				input {
					appearance: none;
					width: 100%;
					height: 100%;
					outline: 0px;
					border: 0px;
					background-color: transparent;
					color: #fff;
					font-size: 15px;
					font-weight: 700;
					line-height: 125%;

					&::placeholder {
						color: $fg-color;
						font-weight: 400;
					}

					&:autofill, &:-webkit-autofill, &:autofill:focus, &:-webkit-autofill:focus {
						border: 0px;
						transition: background-color 600000s 0s, color 600000s 0s;
						background-color: transparent !important;
						color: #fff !important;
					}
			
					&[data-autocompleted] {
						background-color: transparent !important;
						color: #fff !important;
					}
				}
			}
		}

		#app-body-cta {
			opacity: 0;
			animation: appear 0.5s ease-out 1s forwards;
		}
	}

	#app-footer {
		//position: fixed;
		//bottom: 0px;
		width: 100%;

		#app-footer-box {
			position: relative;
			z-index: 2;
			width: 100%;
			opacity: 0;
			animation: appear 0.5s ease-out 1.2s forwards;

			#app-footer-box-overlay {
				display: none;
				position: absolute;
				top: 0px;
				bottom: 0px;
				left:0px;
				right: 0px;
				z-index: 1;
				mask-image: linear-gradient(transparent, rgba(#000, 0.7), #000);
				backdrop-filter: blur(16px);
			}

			#app-footer-box-content {
				@include row(center, center, 64px);
				position: relative;
				z-index: 3;
				padding: 24px 0px;
				padding-bottom: 48px;
				background-image: linear-gradient(transparent, rgba($bg-color, 0.7), $bg-color);
			}
		}

		#app-footer-passify-logo {
			@include center;
			//position: fixed;
			//bottom: 0px;
			//z-index: 5;
			width: 100%;
			margin-bottom: 12px;
			opacity: 0;
			//animation: appear 0.5s ease-out 1.2s forwards;
			animation: appear 0.5s ease-out 1.4s forwards;

			img {
				width: 50px;
			}
		}
	}
}

.btn-back {
	@include center;
	width: 40px;
	height: 40px;
	border-radius: 13px;
	background-color: $bg-color;
	border: 0px;
}

@keyframes appear {
	from {
		opacity: 0;
		transform: translateY(15%);
	}
	to {
		opacity: 1;
		transform: translateY(0%);
	}
}